import { i18n } from '@/locales/i18n-setup'
import store from '@/store'

export interface AccountInterface {
  amount_due: number
  autopay_customer?: string
  autopay_payment_method?: string
  customers: string[]
  description?: string
  due_date: string | null
  external_id: string
  id?: string
  isCurrent?: boolean
  isPastDue?: boolean
  is_autopay: boolean
  merchant_account?: string
  merchant_location: string
  name: string
  nickname?: string
  schedule?: string
  schedule_days?: number[]
  scheduled_payment?: string
  status: string
  tags?: any
  waive_fees: boolean
}

export interface AdhocClaimsInterface {
  roles: string[]
  permission_settings: PermissionSettingsInterface
}

export interface CustomerInterface {
  accounts: AccountInterface[]
  address: string
  address2: string
  city: string
  date_of_birth: string
  email: string
  external_id: string
  first_name: string
  id: string
  invitation_code: string
  is_registered: boolean
  language: string
  last_name: string
  optins: OptinInterface[]
  postal_code: string
  sms_phone_number: string
  ssn_last_four: string
  state_province: string
  user: string
  disabled_payment_methods: Record<string, unknown>
}

export interface Dict {
  [key: string]: any
}

export interface FeeInterface {
  flat?: number
  rate?: number
}

export interface MerchantOrganizationInterface {
  address: string
  address2: string
  apply_billing_threshold: boolean
  apply_convenience_fee_to_automated_payments: boolean
  apply_convenience_fee_to_scheduled_payments: boolean
  apply_convenience_fee_to_misc_payments: boolean
  apply_convenience_fee_to_promises: boolean
  allow_consumer_pay_different_amount: boolean
  billing_cycle: string
  billing_method: string
  city: string
  contract: any
  convenience_fee_billing_threshold: string
  convenience_fees: {
    ACH: FeeInterface
    AMEX: FeeInterface
    CASH: FeeInterface
    DISCOVER: FeeInterface
    MASTERCARD: FeeInterface
    VISA: FeeInterface
  }
  country: string
  currency_code: string
  description: string
  external_id: string
  id: string
  in_legacy_app: boolean
  integration_type: string
  is_integrated: boolean
  locations: string[]
  logo: string
  max_payment_amount: string
  max_payment_percentage: string
  min_payment_amount: string
  min_payment_percentage: string
  name: string
  owner_agents: string[]
  partner: string
  payment_methods: any
  postal_code: string
  ready_to_submit: SubmitResponseInterface
  reply_email: string
  require_cvv: boolean
  shared_secret_fields: string
  state_province: string
  support_email: string
  website_url: string
  check_custom_roles: boolean
  blytzpay_convenience_fee: string
  locations_with_accounts: string[]
  max_max_charge: string
  max_average_charge: string
  max_monthly_volume: string
  autopay_execution_time: string
  processor: string
  is_cost_plus: boolean
}

export interface OptinInterface {
  category: string
  id: string
  media: string
  status: boolean
}

export interface PaymentMethod {
  id: string
  card_type: string
  payment_type: string
  nickname: string
  masked_card_number: string
  masked_account_number: string
}

export interface PaymentMethodTypesInterface {
  ACH?: string
  CC: string
}

export interface PermissionSettingsInterface {
  min_merchant_payment_amount: string
  max_merchant_payment_amount: string
}

export interface PermissionsInterface {
  is_customer_campaign_allowed: boolean
  is_customer_free_text_allowed: boolean
  is_customer_update_allowed: boolean
  is_merchant_passtime_allowed: boolean
  is_merchant_ituran_allowed: boolean
  is_merchant_payment_report_allowed: boolean
  is_merchant_sweep_report_allowed: boolean
  is_note_update_allowed: boolean
  is_payment_refund_allowed: boolean
  is_payment_threshold_allowed: boolean
  is_payment_waive_fee_allowed: boolean
  is_template_update_allowed: boolean
  is_user_update_allowed: boolean
}

export interface PromiseDateInterface {
  date: string
  amount: any
  id: string
  dirty: boolean
  datetime: any
}

export interface ReportTransaction {
  comp__auth_amount: string | null
  comp__card_number: string | null
  comp__card_present: boolean
  comp__card_present_type: string | null
  comp__disc_fee: string | null
  comp__fund_date: string | null
  comp__gross_amount: string | null
  comp__id: string | null
  comp__invoice_number: string | null
  comp__net_amount: string | null
  comp__per_trans_fee: string | null
  comp__mid: string | null
  comp__settle_date: string | null
  comp__transaction_date: string | null
  comp__transaction_description: string | null
  comp__transaction_detail_account: string | null
  comp__email: string | null
  comp__transaction_detail_name: string | null
  comp__transaction_detail_type: string | null
  comp__transaction_number: string | null
  trans__amount: string
  trans__convenience_fee: string | null
  trans__external_id: string | null
  trans__id: string
  trans__multipay_batch_id: string | null
  trans__processed_time: string
  trans__processor_transaction_id: string
  trans__promise_confirmed_time: string | null
  trans__mid: string
  trans__refunded_transaction_id: string | null
  trans__scheduled_date: string | null
  trans__status: string | null
}

export interface RoleInterface {
  name: string
  description: string
  permissions: PermissionsInterface
  settings: RoleSettingsInterface
}

export interface RoleSettingsInterface {
  min_merchant_payment_amount: number | null
  max_merchant_payment_amount: number | null
}

export interface RouteDestinationInterface {
  name: string
  params?: any
}

export interface RouterToInterface {
  name: string
  params?: any
}

export interface SidebarLinkInterface {
  label: string
  dataCy?: string
  conditions: string[]
  to: RouterToInterface
  subItems?: any
  alternate?: string[]
}

export interface SidebarLinksInterface {
  label: string
  to: RouteDestinationInterface | string
  condition?: string[]
  icon: string
  subItems: any
}

export interface SubmitResponseInterface {
  errors: {
    non_field_errors: string
  }
  ready_to_submit: boolean
}

export interface TransactionInterface {
  amount: string
  convenience_fee: any
  created: string
  id: string
  multipay_batch_id: Record<string, unknown>
  payment_method: string
  payment_method_json: Record<string, unknown>
  payment_method_nickname: string
  scheduled_date: string
  status: string
  total_amount: any
}

export interface UserInterface {
  email: string
  first_name: string
  last_name: string
  id: string
  created: string
  is_active: boolean
  roles: string[]
}

export class PaymentMethodOption {
  value: any
  text: string

  constructor(paymentMethod: PaymentMethod | null = null) {
    if (paymentMethod) {
      this.value = {
        id: paymentMethod.id,
        type: paymentMethod.payment_type,
        category: paymentMethod?.card_type ?? paymentMethod.payment_type
      }
      this.text =
        paymentMethod.payment_type === 'CREDIT' ||
        paymentMethod.payment_type === 'DEBIT'
          ? `${
              paymentMethod.nickname
            } - ${paymentMethod.masked_card_number.slice(-8)}`
          : `${
              paymentMethod.nickname
            } - ${paymentMethod.masked_account_number.slice(-8)}`
    } else {
      this.value = {
        id: '',
        type: '',
        category: ''
      }
      this.text = 'Select a payment method'
    }
  }
}

export class Role implements RoleInterface {
  name: string
  description: string
  permissions: PermissionsInterface
  settings: RoleSettingsInterface

  constructor() {
    this.name = ''
    this.description = ''
    this.permissions = {
      is_customer_campaign_allowed: false,
      is_customer_free_text_allowed: false,
      is_customer_update_allowed: false,
      is_merchant_passtime_allowed: false,
      is_merchant_ituran_allowed: false,
      is_merchant_payment_report_allowed: false,
      is_merchant_sweep_report_allowed: false,
      is_note_update_allowed: false,
      is_payment_refund_allowed: false,
      is_payment_threshold_allowed: false,
      is_payment_waive_fee_allowed: false,
      is_template_update_allowed: false,
      is_user_update_allowed: false
    }
    this.settings = {
      min_merchant_payment_amount: 0,
      max_merchant_payment_amount: 0
    }
  }
}

export class User implements UserInterface {
  id!: string
  first_name!: string
  last_name!: string
  email!: string
  is_active!: boolean
  created!: string
  roles!: string[]
  event_mark!: string
  organization_id!: number
  is_expired!: boolean
  is_pending!: boolean
  invite_id!: string

  constructor(data) {
    Object.assign(this, data)
    this.created = i18n.d(new Date(data.created))

    if (store?.getters.currentMerchant?.check_custom_roles) {
      if (this.roles.length) this.roles = [this.roles[0]]
    }
  }

  get status() {
    if (this.is_pending) return 'Pending'
    return this.is_active ? 'Active' : 'Inactive'
  }

  get flatRoles(): string {
    if (!store?.getters.currentMerchant?.check_custom_roles) {
      if (this.roles.includes('admin')) return 'Admin'
      if (this.roles.includes('staff')) return 'Staff'
    }

    return this.roles
      .map(role => role?.charAt(0).toUpperCase() + role?.substring(1))
      .join(', ')
  }

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }
}

class SourceRefund {
  refundingAmount: number
  originalTotal: number
  originalNoFee: number
  fee: number
  label: string
  id: string

  constructor(amount, noFeeAmount, fee, cardLabel, id) {
    this.refundingAmount = amount
    this.originalTotal = amount
    this.originalNoFee = noFeeAmount
    this.fee = fee
    this.label = cardLabel
    this.id = id
  }

  get hasFee(): boolean {
    return !!this.fee
  }
}

export class SourcesRefund {
  noFeeAmountTotal: number
  originalTotal: number
  feeTotal: number
  date: string
  sources: SourceRefund[]

  constructor(transaction) {
    const { payment } = transaction
    const { sources, total_amount } = payment

    this.noFeeAmountTotal = payment.total_no_fee_amount
    this.originalTotal = parseFloat(total_amount)
    this.feeTotal = parseFloat(transaction.fee.replace('$', ''))
    this.date = transaction.date
    this.sources = sources.map(
      source =>
        new SourceRefund(
          source.transaction.total_amount,
          source.amount,
          parseFloat(source.convenience_fee || 0),
          source.transaction.payment_method,
          source.id
        )
    )

    // place source with fee at last index
    const feeIndex = this.sources.findIndex(source => source.hasFee)
    const feeSource = this.sources.splice(feeIndex, 1)
    this.sources = [...this.sources, ...feeSource]
  }

  get refundingTotal(): number {
    return this.sources.reduce(
      (total, source) => total + source.refundingAmount,
      0
    )
  }
}
